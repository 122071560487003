import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import { setPageActive, setPatternActive, setPayloadBukkenSearchStore } from '../features/common/bukkenSlice/stateBukken'
import { createLikeCondition } from '../utils/bukken/helpers/handlePayloadBukkenSearch'

const useSetDefaultStateBukkenSearch = () => {
  const dispatch = useDispatch();
  const progressCondition = useSelector((state) => state.stateBukken.progressCondition);
  const matchBukkenDetail = useMatch("/app/bukken/:id/detail");
  const matchBukken = useMatch("/app/bukken/");
  
  useEffect(() => {
    if (!matchBukkenDetail && !matchBukken) {
      dispatch(setPageActive(1))
      dispatch(setPatternActive(""));
      dispatch(setPayloadBukkenSearchStore({
        info: {
          progress: progressCondition.map(createLikeCondition)
        }
      }))
    }
  }, [dispatch, matchBukken, matchBukkenDetail, progressCondition])
}

export default useSetDefaultStateBukkenSearch