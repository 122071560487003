import { createPortal } from "react-dom";
import { mediaQuery, useMediaQuery } from "../utils/hook/useMediaQuery";

function SuspenseContent() {
  const isSp = useMediaQuery(mediaQuery.sp);

  return createPortal(
    <div className={`loading-indicator ${isSp && "pr-[100px]"}`} ></div>,
    document.body
  );
}

/*
function SuspenseContent() {
  return <div className="w-full h-screen text-gray-300 dark:text-gray-200 bg-base-100">Loading...</div>;
}
*/

export default SuspenseContent;
