import { createSlice } from "@reduxjs/toolkit";

export const syncSlice = createSlice({
  name: "syncFunc",
  initialState: {
    guarded: false,
  },
  reducers: {
    suspend: (state, action) => {
      state.guarded = true;
    },
    resume: (state, action) => {
      state.guarded = false;
    }
  },
});

export const {
  suspend,
  resume
} = syncSlice.actions;

export default syncSlice.reducer;
