import { createSlice } from "@reduxjs/toolkit";

export const rightDrawerSlice = createSlice({
  name: "rightDrawer",
  initialState: {
    header: "", // current  title state management
    isOpen: false, // right drawer state management for opening closing
    bodyType: "", // right drawer content management
    extraObject: {},
    toggleExtraSidebar: true
  },
  reducers: {
    openRightDrawer: (state, action) => {
      const { header, bodyType, extraObject } = action.payload;
      state.isOpen = true;
      state.bodyType = bodyType;
      state.header = header;
      state.extraObject = extraObject;
    },

    closeRightDrawer: (state, action) => {
      state.isOpen = false;
      state.bodyType = "";
      state.header = "";
      state.extraObject = {};
    },
    extendSidebarPortal: (state, action) => {
      state.toggleExtraSidebar = !state.toggleExtraSidebar;
    },
    setFalseSidebar: (state, action) => {
      state.toggleExtraSidebar = false;
    }
  }
});

export const { openRightDrawer, closeRightDrawer, extendSidebarPortal, setFalseSidebar } = rightDrawerSlice.actions;

export default rightDrawerSlice.reducer;
