import { instanceAxios } from "../app/axios";
import TokenService from "./tokenService";
import API_CONFIG from "../config/apiConfig";

export async function refreshAccessToken() {
  const refreshToken = TokenService.getLocalRefreshToken();
  const body = {
    refresh: refreshToken
  };
  try {
    const response = await instanceAxios.post(API_CONFIG.userManager.loginRefresh, body);
    if(!response || !(response.data)) {
      TokenService.removeToken();
      localStorage.removeItem("token");
      window.location.href = "/login";
      throw new Error("Refresh Error");
    }
    const newAccessToken = response.data?.data?.access;
    const newRefreshToken = response.data?.data?.refresh;
    TokenService.updateLocalAccessToken(newAccessToken);
    TokenService.updateLocalRefreshToken(newRefreshToken);
    return newAccessToken;
  } catch (e) {
    Promise.reject(e);
  }
}
