import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

/*
[
    {title, url}
]
*/
function BreadCrumbs({ crumbs }) {
  const createCurrentPage = (item) => {
    return <span>{item.title}</span>;
  };

  const createAncestorPage = (item) => {
    return (
      <div className="bread_crumbs_ancestor">
        <Link to={item.to}>
          <span className="underline">{item.title}</span>
        </Link>
      </div>
    );
  };

  return (
    <div className="breadcrumbs text-base">
      <ul>
        {crumbs.map((item, index) => (
          <li key={uuidv4()}>
            {index === crumbs.length - 1 ? createCurrentPage(item) : createAncestorPage(item)}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BreadCrumbs;
