import React from "react";
import PageCard from "../components/Cards/PageCard";
import { useErrorBoundary } from "react-error-boundary"

const ErrorBoundaryContent = (props) => {
  const { resetBoundary } = useErrorBoundary();

    function logoutUser() {
      localStorage.clear();
      window.location.href = "/";
    }

    // エラーがない場合は、childrenをレンダリングする
    // return this.props.children;
    return (
      <>
        <PageCard>
          <div className="mt-5">
            <p className="text-black font-semibold text-[16px]">
              処理中にエラーが発生しました。
              <br />
              以下をご確認ください。
              <br />
            </p>
            <div className="mt-1 ml-1 text-black font-semibold text-[16px]">
              <ul>
                <li>・インターネットに接続されていますか？</li>
              </ul>
            </div>
            <p className="mt-10 text-black font-semibold text-[16px]">
              状況が改善しない場合は、下記までお問い合わせください。
              <br />
              株式会社システムズナカシマ
              <br />
              https://www.systems.nakashima.co.jp/
              <br />
            </p>
            <div className="mt-10 text-black font-semibold text-[16px] flex gap-2">
              {/* <Link to={"/login"}>ログアウト</Link> */}
              <a onClick={logoutUser} className="cursor-pointer px-3 py-2 border border-gray-500 rounded-md hover:bg-gray-200">ログアウト</a>
              <button className="px-3 py-2 border border-gray-500 rounded-md hover:bg-gray-200" onClick={resetBoundary}>もう一度やり直す</button>
              <button className="px-3 py-2 border border-gray-500 rounded-md hover:bg-gray-200" onClick={() => window.location.href = '/app/portal'}>ポータルに戻る</button>
            </div>
          </div>
        </PageCard>
      </>
    );
}

export default ErrorBoundaryContent;
