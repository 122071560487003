import { TYPE_DATE } from "../../globalConstantUtil";

export const itemBukkenOrder = {
  "bukken_order_uuid": null,
  "bukken_order_name": null,
  "bukken_orderer_manager": [
    {
      "bukken_orderer_manager_uuid": null,
      "bukken_orderer_manager_name": null
    }
  ]
};

export const itemBukkenConstruct = {
  "bukken_construct_uuid": null,
  "bukken_construct_name": null,
  "bukken_construct_manager": [
    {
      "bukken_construct_manager_uuid": null,
      "bukken_construct_manager_name": null
    }
  ]
};

export const joinMember = {
  "company_uuid": null,
  "employee_uuid": null,
  "name": null
};

export const typeDates = [
  {
    name: "完全一致",
    setting: TYPE_DATE.MATCHING
  },
  {
    name: "範囲内",
    setting: TYPE_DATE.WITHIN_RANGE
  },
  {
    name: "範囲外",
    setting: TYPE_DATE.OUT_OF_RANGE
  },
  {
    name: "以前",
    setting: TYPE_DATE.BEFORE
  },
  {
    name: "以降",
    setting: TYPE_DATE.AFTER
  }
];
