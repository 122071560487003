import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API_CONFIG from "../../../config/apiConfig";
import TokenService from "../../../services/tokenService";
import jwtDecode from "jwt-decode";
import { instanceAxios } from "../../../app/axios";

export const listCompanyApi = createAsyncThunk("/listCompany", async () => {
  const accessToken = TokenService.getLocalAccessToken();
  const { user_uuid } = jwtDecode(accessToken);
  const body = {
    data: {
      user_uuid,
    },
  };
  try {
    const response = await instanceAxios.post(
      API_CONFIG.userManager.searchCompany,
      body,
    );
    if (response.data.data.companies && !localStorage.getItem("uuidCompany")) {
      localStorage.setItem(
        "uuidCompany",
        response.data.data.companies[0].company_uuid,
      );

      if(localStorage.getItem("contractSystems") == null) {
        const serverData = await instanceAxios.get(`/portal/contract/info/${response.data.data.companies[0].company_uuid}`);
        localStorage.setItem("contractSystems", JSON.stringify(serverData.data.data));
      }
    }
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
});

export const listCompanySlice = createSlice({
  name: "list-company-slice",
  initialState: {
    arrCompany: [],
    uuidActive: localStorage.getItem("uuidCompany"),
    uuidChoose: "",
    isLoading: false,
    errorMessage: "",
  },
  reducers: {
    setArrCompany: (state, action) => {
      const newArray = state.arrCompany.map((company) => {
        if (company.company_uuid === action.payload.company_uuid) {
          return action.payload;
        }
        return company;
      });
      state.arrCompany = newArray;
    },
    setUuidActive: (state, action) => {
      if(action.payload){
        localStorage.setItem("uuidCompany", action.payload);
        state.uuidActive = action.payload;

        // 使用中会社情報の更新
        // const accessToken = TokenService.getLocalAccessToken();
        // const { user_uuid } = jwtDecode(accessToken);
        // const res = await instanceAxios.post(`/user-manager/users/account/edit-activeCompany/${action.payload}/${user_uuid}`)
      }
    },
    setUuidChoose: (state, action) => {
      state.uuidChoose = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listCompanyApi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listCompanyApi.fulfilled, (state, action) => {
        state.arrCompany = action.payload.data.companies;
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(listCompanyApi.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = "API LIST COMPANY ERROR";
      });
  },
});

export const { setArrCompany, setUuidActive, setUuidChoose } =
  listCompanySlice.actions;

export default listCompanySlice.reducer;
