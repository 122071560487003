import _ from "lodash"
import { BASIC_INFO_UUID_LIST, BUKKEN_TAB_UUID, ITEM_TYPE, TYPE_DATE } from "../../globalConstantUtil"

const UUIDS = {
  BUKKEN_ORDER: "00000000000000000000000000000017",
  BUKKEN_ORDER_MANAGER: "00000000000000000000000000000018",
  BUKKEN_CONSTRUCT: "00000000000000000000000000000019",
  CONSTRUCT_START_DT: "00000000000000000000000000000024", 
  CONSTRUCT_END_DT: "00000000000000000000000000000025", 
  BUKKEN_MEMBER: "00000000000000000000000000000023" 
};

const listBukkenItemHavNameAndUuid = [
  "00000000000000000000000000000002",
  "00000000000000000000000000000014",
  "00000000000000000000000000000015",
  "00000000000000000000000000000021",
  "00000000000000000000000000000022"
];

const listBukkenNestedCondition = [
  "00000000000000000000000000000017",
  "00000000000000000000000000000018",
  "00000000000000000000000000000019",
  "00000000000000000000000000000020"
];

const listBukkenDefaultArray = ["00000000000000000000000000000001", "00000000000000000000000000000007"]

// Helper functions
const createEqualityCondition = (keyword, condition = "EQ") => ({ keyword, condition });

export const createLikeCondition = (keyword) => ({ keyword, condition: "LIKE" });

const splitAndMap = (value, mapFn) => value.split(",").map(mapFn);

const handleNameAndUuid = (item, bukken_item_uuid_name, bukken_item_name) => {
  if (!item?.value2 || !item?.value) return;
  const payloadPerField = {};
  const arrValue = item.value.split(",");
  const arrValue2 = item.value2.split(",");
  payloadPerField[bukken_item_uuid_name] = arrValue2.map(val => createEqualityCondition(val));
  payloadPerField[bukken_item_name] = arrValue.map(val => createEqualityCondition(val));
  return payloadPerField;
};
/* handle bukken_orderer and bukken_construct */
const handleNestedCondition = (item, formatBukkenItemUuid, bukken_orderer, bukken_construct) => {
  const result = {};
  
  if (!item?.value2) return;
  switch(formatBukkenItemUuid) {
    case UUIDS.BUKKEN_ORDER:
      bukken_orderer[0]["bukken_order_uuid"] = createEqualityCondition(item.value2);
      break;
    case UUIDS.BUKKEN_ORDER_MANAGER:
      bukken_orderer[0]["bukken_orderer_manager"] = [];
      bukken_orderer[0]["bukken_orderer_manager"].push({
        bukken_orderer_manager_uuid: createEqualityCondition(item.value2)
      });
      break;
    case UUIDS.BUKKEN_CONSTRUCT:
      bukken_construct[0]["bukken_construct_uuid"] = createEqualityCondition(item.value2);
      break;
    default:
      bukken_construct[0]["bukken_construct_manager"]=[];
      bukken_construct[0]["bukken_construct_manager"].push({
        bukken_construct_manager_uuid: createEqualityCondition(item.value2)
      });
  }
  
  if (Object.keys(bukken_orderer[0]).length > 0) {
    result.bukken_orderer = bukken_orderer;
  }
  if (Object.keys(bukken_construct[0]).length > 0) {
    result.bukken_construct = bukken_construct;
  }

  return result;
};

/* handle bukken_member */
const handleBukkenMember = (item) => {
  if (!item?.value2) return;
  
  let bukken_member = [{
    employee_uuid: createEqualityCondition(item.value2)
  }]
  
  if (item?.customer_uuid) {
    bukken_member[0]["company_uuid"] = createEqualityCondition(item.customer_uuid);
  }
  
  return {
    bukken_member
  };
};
/* handle construct_date */
const handleConstructDate = (item, formatBukkenItemUuid) => {
  if (!item.value || item?.value === ":") return;

  const isStartDate = formatBukkenItemUuid === UUIDS.CONSTRUCT_START_DT;
  const dateField = isStartDate ? "construct_start_dt" : "construct_end_dt";

  const handleRangeType = (rangeType) => {
    const [from_dt, to_dt] = item.value.split(":");
    return {
      [dateField]: {
        from_dt,
        to_dt,
        condition: rangeType === TYPE_DATE.WITHIN_RANGE ? "BETWEEN" : "OUT_RANGE"
      }
    };
  };

  const conditionMap = {
    [TYPE_DATE.MATCHING]: "EQ",
    [TYPE_DATE.AFTER]: "GT",
    [TYPE_DATE.BEFORE]: "LT",
    [TYPE_DATE.WITHIN_RANGE]: handleRangeType,
    [TYPE_DATE.OUT_OF_RANGE]: handleRangeType
  };

  const condition = conditionMap[item.setting];

  if (typeof condition === "function") {
    return condition(item.setting);
  }

  return { [dateField]: createEqualityCondition(item.value, condition || "EQ") };
};

const handleDefaultCase = (item, formatBukkenItemUuid) => {
  if (!item.value) return;  
  const bukken_item_name = BASIC_INFO_UUID_LIST[formatBukkenItemUuid];
  if (listBukkenDefaultArray.includes(formatBukkenItemUuid)) { 
    return {
      [bukken_item_name]: splitAndMap(item.value, createLikeCondition)
    };
  }
  
  return {
    [bukken_item_name]: createLikeCondition(item.value)
  }
};

// Main function
export function handleBasicPayload(dataBasic) {
  const bukken_orderer = [{}];
  const bukken_construct = [{}];
  return dataBasic.reduce((result, item) => {
    const bukken_item_uuid = item?.bukken_item_uuid;
    const formatBukkenItemUuid = bukken_item_uuid?.replace(/-/g, "");

    let newData = {};

    if (listBukkenItemHavNameAndUuid.includes(formatBukkenItemUuid)) {
      const bukken_item_uuid_name = BASIC_INFO_UUID_LIST[formatBukkenItemUuid];
      const bukken_item_name = bukken_item_uuid_name?.replace(/_uuid/g, "_name");
      newData = handleNameAndUuid(item, bukken_item_uuid_name, bukken_item_name);
    } else if (listBukkenNestedCondition?.includes(formatBukkenItemUuid)) {
      newData = handleNestedCondition(item, formatBukkenItemUuid, bukken_orderer, bukken_construct);
    } else if (formatBukkenItemUuid === UUIDS.BUKKEN_MEMBER) {
      newData = handleBukkenMember(item);
    } else if (UUIDS.CONSTRUCT_START_DT === formatBukkenItemUuid || UUIDS.CONSTRUCT_END_DT === formatBukkenItemUuid) {
      newData = handleConstructDate(item, formatBukkenItemUuid);
    } else {
      newData = handleDefaultCase(item, formatBukkenItemUuid);
    }

    return { ...result, ...newData };
  }, {});
}

export function handlePayloadBukkenSearch(conditionOfPatterNameActive) {
  const [basic, advanced] = _.partition(conditionOfPatterNameActive, (codition) => {
    return codition?.bukken_tab_uuid === BUKKEN_TAB_UUID;
  });

  const payloadBasic = handleBasicPayload(basic);
  const payloadAdvanced = handleAdvancedPayload(advanced);
  
  const result = {
    ...payloadBasic
  };

  if (payloadAdvanced.length > 0) {
    result.advanced_info = payloadAdvanced;
  }
  return result;
}

export function handleAdvancedPayload(dataAdvanced) {
  const result = [];
  for (let item of dataAdvanced) {
    if (!item?.value) continue;
    for (let value of item?.value.split(",")) {
      result.push({
        bukken_item_uuid: {
          keyword: item?.bukken_item_uuid,
          condition: "EQ"
        },
        value: {
          keyword: String(value).trim(),
          condition: item?.item_type === ITEM_TYPE.INPUT ? "LIKE" : "EQ"
        }
      });
    }
  }

  return result;
}
/* 
  Only when the time has the following structure is it necessary to validate
  {
    "from_dt": "string",
    "to_dt": "string",
    "condition": "enum(BETWEEN, OUT_RANGE)"
  },
*/
export const validateWithOutFormDt_OR_ToDtSearch = (construct_start_dt, construct_end_dt) => {
  if (construct_start_dt && Object.keys(construct_start_dt)?.length > 2) {
    if (!construct_start_dt?.from_dt || !construct_start_dt?.to_dt) {
      return false;
    }
  }
  if (construct_end_dt && Object.keys(construct_end_dt)?.length > 2) {
    if (!construct_end_dt?.from_dt || !construct_end_dt?.to_dt) {
      return false;
    }
  }

  return true;
};
