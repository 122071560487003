import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API_CONFIG from "../../../config/apiConfig";
import { instanceAxios } from "../../../app/axios";
export const inforCompanyApi = createAsyncThunk(
  "/inforCompany",
  async (uuid) => {
    try {
      const response = await instanceAxios.get(
        API_CONFIG.userManager.getCompany(uuid),
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
);
export const companyInfoSlice = createSlice({
  name: "company-info-slice",
  initialState: {
    inforCompany: {},
    storageCompany: [],
    capacity: 0,
    isLoading: false,
    errorMessage: "",
  },
  reducers: {
    setStorageCompany: (state, action) => {
      state.storageCompany = action.payload;
    },
    setInforCompany: (state, action) => {
      state.inforCompany = action.payload;
    },
    setCapacity: (state, action) => {
      state.capacity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(inforCompanyApi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(inforCompanyApi.fulfilled, (state, action) => {
        state.inforCompany = action.payload.data.info;
        state.capacity =
          action.payload.data.setting.capacity.storage * 1073741824;
        state.storageCompany = action.payload.data.storage;
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(inforCompanyApi.rejected, (state) => {
        state.isLoading = false;
        state.inforCompany = {};
        state.storageCompany = [];
        state.capacity = 0;
        state.errorMessage = "API FormCompany ERROR";
      });
  },
});

export const { setCapacity, setInforCompany, setStorageCompany } =
  companyInfoSlice.actions;

export default companyInfoSlice.reducer;
