const pagesConfig = {
  login: {
    name: "login",
    path: "/login"
  },
  logout: {
    path: "/logout"
  },
  forgot: {
    path: "/app/update-password"
  },
  portal: {
    path: "/app/portal"
  },
  companyInfo: {
    path: "/company-info"
  },
  storageDetail: {
    path: "/detail-storage"
  },
  adminSetting: {
    path: "/app/portal/company-setting"
  },
  recycleBin: {
    path: "/recycle-bin"
  },
  sncPortal: {
    path: "/app/snc-admin/company"
  },
  companyAdmin: {
    path: "snc-admin/company"
  },
  userType: {
    system  : '1',  // システム
    user    : '2',  // ユーザー
    snc_user: '3'   // SNCユーザー
  },
  bukken_item_mst: {
    columnOptions: [
      { id: 1, value: 1 },
      { id: 2, value: 2 },
      { id: 3, value: 3 }
    ],
    itemListOptions: [
      { id: -1, value: "空白" },
      { id: 0, value: "文字" },
      { id: 1, value: "日付" },
      { id: 2, value: "セレクトボックス" },
      { id: 3, value: "ラジオボタン" },
      { id: 4, value: "チェックボックス" },
      { id: 5, value: "社員" },
      { id: 6, value: "部署" },
      { id: 7, value: "得意先" },
      { id: 8, value: "得意先担当者" },
      { id: 9, value: "工種" },
      { id: 10, value: "請負区分" },
      //{ id: 11, value: "部屋" },
      { id: 12, value: "進捗状況" },
      // { id: 13, value: "関連物件" },
      // { id: 14, value: "参加者" },
      { id: 15, value: "ラベル" },
      { id: 16, value: "電話番号" },
      { id: 17, value: "資格情報" },
      { id: 18, value: "住所" },
    ]
  },
  // ページングの項目数
  paging:{
    perPage : 10,   // 一覧画面
    perDialog : 5   // ダイアログ
  }
};

export default pagesConfig;

