import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API_CONFIG from "../../../../config/apiConfig";
import { instanceAxios } from "../../../../app/axios";

export const folderChildren = createAsyncThunk(
  "/folderChildren",
  async (uuid) => {
    const body = {
      data: {
        system_uuid: "00000000-0000-0000-0000-000000000000",
        folder_uuid: uuid || "0",
        target_mode: 2,
      },
    };
    try {
      const response = await instanceAxios.post(
        API_CONFIG.folderManager.getChildren(
          localStorage.getItem("uuidCompany"),
        ),
        body,
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
);

export const folderChildrenSlice = createSlice({
  name: "folderChildren",
  initialState: {
    data: "",
    isLoading: false,
    errorMessage: "",
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(folderChildren.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(folderChildren.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(folderChildren.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = "Have some error with Api recycle";
      });
  },
});

export const { setData } = folderChildrenSlice.actions;
export default folderChildrenSlice.reducer;
