import axios from "axios";
import TokenService from "../services/tokenService";
import { refreshAccessToken } from "../services/refreshAccessToken";
import pagesConfig from "../config/config";
import API_CONFIG from "../config/apiConfig";
import { instanceAxios } from "./axios";

const checkAuth = (accessToken) => {
  /*  Getting token value stored in localstorage, if token is not present we will open login page for all internal dashboard routes  */
  const TOKEN = accessToken ?? TokenService.getLocalAccessToken();
  const PUBLIC_ROUTES = [
    pagesConfig.login.name,
    "forgot-password",
    "register",
    "documentation",
  ];
  axios.defaults.baseURL = API_CONFIG.apiUrl;
  const isPublicPage = PUBLIC_ROUTES.some((r) =>
    window.location.href.includes(r),
  );
  const TOKEN_EXPIRED_MESSAGE = "token_not_valid";
  const TWO_ACCOUNT_LOGIN = "Authentication credentials were not provided.";

  const URL_API_UPLOAD = ["/file-manager/files/raw/"];

  const REFRESH_TOKEN_URL  = "/user-manager/login/refresh";


  // FIXME テスト用設定
  //if (!TOKEN && !isPublicPage) {
  if (false)
  {
    window.location.href = pagesConfig.login.path;
    return;
  }
  else
  {
    instanceAxios.interceptors.request.use(
      function (config) {
        // document.body.classList.add("loading-indicator");
        const accessToken = TokenService.getLocalAccessToken();
        if (accessToken) {
          if (!URL_API_UPLOAD.some((url) => config?.url.includes(url))) {
            config.headers["Content-Type"] = "application/json";
          }
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    instanceAxios.interceptors.response.use(
      function (response) {
        // UPDATE: Add this code to hide global loading indicator
        document.body.classList.remove("loading-indicator");
        return response;
      },
      async function (error) {
        document.body.classList.remove("loading-indicator");
        const originConfig = error.config;
        if (
          error?.response?.data?.status === 200 &&
          error?.response?.data?.msg?.code === TOKEN_EXPIRED_MESSAGE &&
          !originConfig._retry
        ) {
          originConfig._retry = true;
          try {
            // リフレッシュトークンによるアクセストークン再取得処理にて401エラーが発生した場合(=リフレッシュトークンの期限が切れている場合)
            // ログインページに飛ばす
            if(error?.response?.config?.url === REFRESH_TOKEN_URL) {
              window.location.href = "/login";
              return;
            }
            window.refreshToken = window.refreshToken ? window.refreshToken : refreshAccessToken();
            await window.refreshToken;
            window.refreshToken = null;
            return instanceAxios(originConfig);
          } catch (error) {
            TokenService.removeToken();
            window.location.href = "/login";
            return Promise.reject(error);
          }
        } else if (error?.response?.data?.msg?.detail === TWO_ACCOUNT_LOGIN) {
          TokenService.removeToken();
          localStorage.removeItem("token");
          localStorage.removeItem("uuidCompany");
          localStorage.removeItem("contractSystems");
          localStorage.removeItem("userType");
          localStorage.removeItem("dev");
          window.location.href = "/login";
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );
    return TOKEN;
  }


  // NOTE VAIX側で修正がかかっている(旧処理をコメントアウト)
  /*
  instanceAxios.interceptors.response.use(
    function (response) {
      // UPDATE: Add this code to hide global loading indicator
      document.body.classList.remove("loading-indicator");
      return response;
    },
    async function (error) {
      document.body.classList.remove("loading-indicator");
      const originConfig = error.config;
      if (error?.response?.data?.status === 201 && !originConfig?.sent) {
        try {
          originConfig.sent = true;
          await refreshAccessToken();
          originConfig.headers["Authorization"] = "Bearer " + TokenService.getLocalAccessToken();
          return axios(originConfig);
        }
        catch (error) {
          return Promise.reject(error);
        }
      }
      if (
        error?.response?.status === 401 &&
        error.response.data.msg.detail === TOKEN_EXPIRED_MESSAGE &&
        !originConfig._retry
      ) {
        originConfig._retry = true;
        try {
          window.refreshToken = window.refreshToken
            ? window.refreshToken
            : refreshAccessToken();
          await window.refreshToken;
          window.refreshToken = null;
          return instanceAxios(originConfig);
        }
        catch (error) {
          window.location.href = "/login";
          TokenService.removeToken();
          return Promise.reject(error);
        }
      }
      if (
        error?.response?.status === 401 &&
        (error.response.data.msg.detail === TWO_ACCOUNT_LOGIN || error.response.data.msg.detail === TOKEN_EXPIRED_MESSAGE)
      ) {
        window.location.href = "/login";
        TokenService.removeToken();
        return Promise.reject(error);
      }
      else {
        console.log(error);
        // window.location.href = "/login"
        //   localStorage.clear();
      }
        return Promise.reject(error);
    }
  );
    return TOKEN;
  }
  */
};

export default checkAuth;
