const API_CONFIG = {
  // Domain
  apiUrl: process.env.REACT_APP_API_URL + "/api",

  // User Manager API List
  userManager: {
    login: "/user-manager/login",
    loginRefresh: "/user-manager/login/refresh",
    logout: "/user-manager/logout",
    getAllUser: "/user-manager/users/systems/all",
    searchCompany: "/user-manager/companies/search",
    getCompany: (company_uuid) => `/user-manager/companies/${company_uuid}`,
    editCompany: (company_uuid) => `/user-manager/companies/edit/${company_uuid}`
  },

  // File Manager API List
  fileManager: {
    deletePermanent: (company_uuid) => `/file-manager/files/delete-permanent/${company_uuid}`,
    dowloadMulti: (company_uuid) => `/file-manager/files/download/multi/${company_uuid}`,
    deleteRecycleBin: (company_uuid) => `/file-manager/folders/delete-permanent/${company_uuid}`,
    getInfoFile: (company_uuid, file_uuid) => `/file-manager/files/thumbnails/${company_uuid}/${file_uuid}`
  },

  // Folder Manager API List
  folderManager: {
    getChildren: (company_uuid) => `/file-manager/folders/children/${company_uuid}`,
    deletePermanent: (company_uuid) => `/file-manager/folders/delete-permanent/${company_uuid}`,
    download: (company_uuid, folder_uuid) => `/file-manager/folders/download/${company_uuid}/${folder_uuid}`,
    getFolderChildren: (company_uuid) => `/file-manager/folders/children/${company_uuid}`,

    downloadFile: (company_uuid, file_uuid) => `/file-manager/files/raw/${company_uuid}/${file_uuid}`,

    downloadFolder: (company_uuid, folder_uuid) => `/file-manager/folders/download/${company_uuid}/${folder_uuid}`,

    deleteFile: (company_uuid, file_uuid) => `/file-manager/files/delete/${company_uuid}/${file_uuid}`,

    deleteFolder: (company_uuid, folder_uuid) => `/file-manager/folders/delete/${company_uuid}/${folder_uuid}`,

    downloadMultiFiles: (company_uuid) => `/file-manager/files/download/multi/raw/${company_uuid}`,

    registerFolder: (company_uuid) => `/file-manager/folders/${company_uuid}`,

    registerFile: (company_uuid) => `/file-manager/files/${company_uuid}`,

    createFile: (company_uuid) => `/file-manager/files/raw/${company_uuid}`,

    changeFolderName: (company_uuid, folder_uuid) => `/file-manager/folders/${company_uuid}/${folder_uuid}`,

    changeFileName: (company_uuid, file_uuid) => `/file-manager/files/${company_uuid}/${file_uuid}`,

    deleteBukkenFile: (company_uuid, file_uuid,bukken_uuid) => `bukken-manager/bukken-file-manager/files/delete/${company_uuid}/${file_uuid}/${bukken_uuid}`,

    deleteBukkenFolder: (company_uuid, folder_uuid,bukken_uuid) => `bukken-manager/bukken-file-manager/folders/delete/${company_uuid}/${folder_uuid}/${bukken_uuid}`,

    deleteBukkenPhoto: (company_uuid, folder_uuid,bukken_uuid) => `bukken-manager/bukken-file-manager/photo/delete/${company_uuid}/${folder_uuid}/${bukken_uuid}`,
    
    deleteMstFile: (company_uuid, file_uuid) => `bukken-manager/bukken-file-manager/files-mst/delete/${company_uuid}/${file_uuid}`,

    deleteMstFolder: (company_uuid, folder_uuid) => `bukken-manager/bukken-file-manager/folders-mst/delete/${company_uuid}/${folder_uuid}`

  },
  bukken: {
    // 物件を登録する
    post: (company_uuid) => `/bukken-manager/bukken/${company_uuid}`,
    // 物件を論理削除する
    delete: (company_uuid, bukken_uuid) => `/bukken-manager/bukken/delete/${company_uuid}/${bukken_uuid}`,
    // 物件を編集する
    edit: (company_uuid, bukken_uuid) => `/bukken-manager/bukken/edit/${company_uuid}/${bukken_uuid}`,
    // 物件を完全削除する
    deletePermanent: (company_uuid, bukken_uuid) =>
      `/bukken-manager/bukken/delete-permanent/${company_uuid}/${bukken_uuid}`,
    // 物件を検索する
    search: (company_uuid) => `/bukken-manager/bukken/search/${company_uuid}`,
    searchAdvanced: (company_uuid) => `/bukken-manager/bukken/search/advance/${company_uuid}`,
    // 物件情報を取得する
    info: (company_uuid, bukken_uuid) => `/bukken-manager/bukken/info/${company_uuid}/${bukken_uuid}`,
    // 関連物件情報を取得する
    relatedInfo: (company_uuid, bukken_uuid) => `/bukken-manager/bukken/related_info/${company_uuid}/${bukken_uuid}`,
    // bukken-folders -> bukken-folders/search/${company_uuid}/${bukken_uuid}
    searchFolders: (company_uuid, bukken_uuid) => `/bukken-manager/bukken-folders/search/${company_uuid}/${bukken_uuid}`,

    rebuild: (company_uuid, bukken_uuid) => `/bukken-manager/bukken/rebuild/${company_uuid}/${bukken_uuid}`,

    dublicate: (company_uuid, bukken_uuid) => `/bukken-manager/bukken/duplicate/${company_uuid}/${bukken_uuid}`
  },
  //
  bukkenDispItem: {
    // 物件一覧表示項目を編集する
    edit: (company_uuid) => `/bukken-manager/bukken-disp-item/edit/${company_uuid}`,
    // 物件一覧表示項目を取得する
    info: (company_uuid) => `/bukken-manager/bukken-disp-item/info/${company_uuid}`
  },
  //
  bukkenSearch: {
    // 物件検索条件を編集する
    post: (company_uuid) => `/bukken-manager/bukken-search/${company_uuid}`,
    edit: (company_uuid, search_pattern_uuid) =>
      `/bukken-manager/bukken-search/edit/${company_uuid}/${search_pattern_uuid}`,
    // 物件検索条件を取得する
    info: (company_uuid) => `/bukken-manager/bukken-search/info/${company_uuid}`,
    delete_permanent: (company_uuid, search_pattern_uuid) =>
      `/bukken-manager/bukken-search/delete-permanent/${company_uuid}/${search_pattern_uuid}`
  },
  //
  bukkenClassMst: {
    // 物件区分マスタを登録する
    post: (company_uuid) => `/bukken-manager/bukken-class-mst/${company_uuid}`,
    // 物件区分マスタを論理削除する
    delete: (company_uuid, bukken_class_uuid) =>
      `/bukken-manager/bukken-class-mst/delete/${company_uuid}/${bukken_class_uuid}`,
    // 物件区分マスタを編集する
    edit: (company_uuid, bukken_class_uuid) =>
      `/bukken-manager/bukken-class-mst/edit/${company_uuid}/${bukken_class_uuid}`,
    // 物件区分マスタに紐づくタブ情報と項目情報を取得する
    info: (company_uuid, bukken_class_uuid) =>
      `/bukken-manager/bukken-class-mst/info/${company_uuid}/${bukken_class_uuid}`,
    // 物件区分マスタのリストを取得する
    all: (company_uuid) => `/bukken-manager/bukken-class-mst/all/${company_uuid}`
  },
  //
  bukkenTabMst: {
    // 物件タブマスタを登録する
    post: (company_uuid) => `/bukken-manager/bukken-tab-mst/${company_uuid}`,
    // 物件タブマスタを論理削除する
    delete: (company_uuid, bukken_tab_uuid) =>
      `/bukken-manager/bukken-tab-mst/delete/${company_uuid}/${bukken_tab_uuid}`,
    // 物件タブマスタを編集する
    edit: (company_uuid, bukken_tab_uuid) => `/bukken-manager/bukken-tab-mst/edit/${company_uuid}/${bukken_tab_uuid}`,
    // 物件タブマスタ情報を取得する
    info: (company_uuid, bukken_tab_uuid) => `/bukken-manager/bukken-tab-mst/info/${company_uuid}/${bukken_tab_uuid}`,
    // 物件タブマスタリストを取得する
    all: (company_uuid) => `/bukken-manager/bukken-tab-mst/all/${company_uuid}`,
    //
    editList: (company_uuid, bukken_class_uuid) =>
      `/bukken-manager/class-tab-list/edit/${company_uuid}/${bukken_class_uuid}`
  },
  //
  classTabList: {
    // 区分ごとのタブリストマスタを編集する
    edit: (company_uuid, bukken_class_uuid) => `/class-tab-list/edit/${company_uuid}/${bukken_class_uuid}`
  },
  //
  bukkenItemMst: {
    // 物件項目マスタを登録する
    post: (company_uuid, bukken_tab_uuid) => `/bukken-manager/bukken-item-mst/${company_uuid}/${bukken_tab_uuid}`,
    // 物件項目マスタを論理削除する
    delete: (company_uuid, bukken_item_uuid) =>
      `/bukken-manager/bukken-item-mst/delete/${company_uuid}/${bukken_item_uuid}`,
    // 物件項目マスタを編集する
    edit: (company_uuid, bukken_item_uuid) => `/bukken-manager/bukken-item-mst/edit/${company_uuid}`,
    // 物件項目マスタを取得する
    get: (company_uuid, bukken_item_uuid) => `/bukken-manager/bukken-item-mst/get/${company_uuid}/${bukken_item_uuid}`,
    search: (company_uuid) => `/bukken-manager/bukken-item-mst/search/${company_uuid}`
  },
  //
  bukkenTemplateMst: {
    // 物件テンプレートマスタを登録する
    post: (company_uuid) => `/bukken-manager/bukken-template-mst/${company_uuid}`,
    // 物件テンプレートマスタを論理削除する
    delete: (company_uuid, bukken_template_uuid) =>
      `/bukken-manager/bukken-template-mst/delete/${company_uuid}/${bukken_template_uuid}`,
    // 物件テンプレートマスタを編集する
    edit: (company_uuid, bukken_template_uuid) =>
      `/bukken-manager/bukken-template-mst/edit/${company_uuid}/${bukken_template_uuid}`,
    // 物件テンプレートマスタを完全削除する
    deletePermanent: (company_uuid, bukken_template_uuid) =>
      `/bukken-manager/bukken-template-mst/delete-permanent/${company_uuid}/${bukken_template_uuid}`,
    // 物件テンプレートマスタを取得する
    get: (company_uuid) => `/bukken-manager/bukken-template-mst/get/${company_uuid}`
  },
  //
  constTypeMst: {
    // 工種マスタを登録する
    post: (company_uuid) => `/bukken-manager/const-type-mst/${company_uuid}`,
    // 工種マスタを論理削除する
    delete: (company_uuid, const_type_uuid) =>
      `/bukken-manager/const-type-mst/delete/${company_uuid}/${const_type_uuid}`,
    // 工種マスタを編集する
    edit: (company_uuid, const_type_uuid) => `/bukken-manager/const-type-mst/edit/${company_uuid}/${const_type_uuid}`,
    // 工種マスタ一覧を取得する
    all: (company_uuid, bukken_class_uuid) => `/bukken-manager/const-type-mst/all/${company_uuid}/${bukken_class_uuid}`
  },
  //
  locationMst: {
    // 地域マスタを登録する
    post: (company_uuid) => `/bukken-manager/location-mst/${company_uuid}`,
    // 地域マスタを論理削除する
    delete: (company_uuid, location_uuid) => `/bukken-manager/location-mst/delete/${company_uuid}/${location_uuid}`,
    // 地域マスタを編集する
    edit: (company_uuid, location_uuid) => `/bukken-manager/location-mst/edit/${company_uuid}/${location_uuid}`,
    // 地域マスタ一覧を取得する
    all: (company_uuid) => `/bukken-manager/location-mst/all/${company_uuid}`
  },
  //
  bukkenPhotoFolders: {
    // 物件写真フォルダを登録する
    registerImageFolder: (company_uuid, bukken_uuid) =>
      `/bukken-manager/bukken-photo-folders/${company_uuid}/${bukken_uuid}`,

    // 物件写真フォルダを編集する
    changeImageFolderName: (company_uuid, photo_folder_uuid) =>
      `/bukken-manager/bukken-photo-folders/edit/${company_uuid}/${photo_folder_uuid}`,

    // 物件写真フォルダ情報を取得する
    info: (company_uuid, photo_folder_uuid) =>
      `/bukken-manager/bukken-photo-folders/children/photo/info/${company_uuid}/${photo_folder_uuid}`,
    // 物件写真フォルダを検索する
    search: (company_uuid) => `/bukken-manager/bukken-photo-folders/search/${company_uuid}`,
    // delete
    delete: (company_uuid, folder_uuid) => `/bukken-manager/bukken-photo-folders/delete/${company_uuid}/${folder_uuid}`
  },
  customer: {
    search: (company_uuid) => `/company-manager/customer/search/${company_uuid}`
  },
  department: {
    search: (company_uuid) => `/company-manager/department/info/${company_uuid}`
  },
  employee: {
    search: (company_uuid) => `/company-manager/employee/search/${company_uuid}`
  },
  customerGroup: {
    search: (company_uuid) => `/company-manager/customer-group/search/${company_uuid}`
  },
  customerEmployee: {
    search: (company_uuid, customer_uuid) => `/company-manager/customer-employee/search/${company_uuid}/${customer_uuid}`
  },

  quotation: {
    // 見積書登録
    post: (company_uuid) => `/quotation-manager/quotation/${company_uuid}`,
    // 見積書情報取得
    get: (company_uuid, quotation_uuid) => `/quotation-manager/quotation/${company_uuid}/${quotation_uuid}`,
    // 見積書編集
    edit: (company_uuid, quotation_uuid) => `/quotation-manager/quotation/edit/${company_uuid}/${quotation_uuid}`,
    // 見積書一覧取得
    list: (company_uuid, bukken_uuid) => `/quotation-manager/quotation/list/${company_uuid}/${bukken_uuid}`,
    // 見積書論理削除
    delete: (company_uuid) => `/quotation-manager/quotation/delete/${company_uuid}`,
    // 見積書発行
    issue: (company_uuid, quotation_uuid) => `/quotation-manager/quotation/issue/${company_uuid}/${quotation_uuid}`,
    // 見積作成用マスタ情報取得
    mst: () => "/quotation-manager/quotation/mst",
    // 明細マスタ項目作成
    item: (company_uuid) => `/quotation-manager/quotation/item/${company_uuid}`,
    // 明細マスタ項目更新
    itemEdit: (company_uuid, mst_quotation_item_uuid) =>
      `/quotation-manager/quotation/item/edit/${company_uuid}/${mst_quotation_item_uuid}`,
    // 明細マスタ一覧取得
    itemList: (company_uuid) => `/quotation-manager/quotation/item/list/${company_uuid}`,
    // 明細マスタ項目の論理削除
    itemDelete: (company_uuid) => `/quotation-manager/quotation/item/delete/${company_uuid}`,

    importCsv: (company_uuid) => `/quotation-manager/quotation/import/item/${company_uuid}`,

    lastInput: (company_uuid) => `/quotation-manager/quotation/last-input/${company_uuid}`
  },

  invoice: {
    // 請求書作成
    post: (company_uuid, bukken_uuid) => `/invoice-manager/invoice/${company_uuid}`,
    // 請求書取得
    get: (company_uuid, invoice_uuid) => `/invoice-manager/invoice/${company_uuid}/${invoice_uuid}`,
    // 請求書更新
    edit: (company_uuid, invoice_uuid) => `/invoice-manager/invoice/edit/${company_uuid}/${invoice_uuid}`,
    // 請求書一覧取得
    list: (company_uuid, bukken_uuid) => `/invoice-manager/invoice/list/${company_uuid}/${bukken_uuid}`,
    // 請求書の論理削除
    delete: (company_uuid) => `/invoice-manager/invoice/delete/${company_uuid}`,
    // 請求書発行
    issue: (company_uuid, invoice_uuid) => `/invoice-manager/invoice/issue/${company_uuid}/${invoice_uuid}`,
    // 前回入力請求書データ取得
    lastInput: (company_uuid) => `/invoice-manager/invoice/last-input/${company_uuid}`,
    // 請求書作成に必要なマスタデータ取得
    mst: () => "/invoice-manager/invoice/mst"
  },

  // shashikanri api config
  shashiKanri: {
    // create photobook
    createPhotobook: (company_uuid) => `/photobook/${company_uuid}/`,
    // get data photobook
    getPhotobook: (company_uuid, photobook_uuid) => `/photobook/${company_uuid}/${photobook_uuid}`,
    // edit photobook
    editPhotobook: (company_uuid, photobook_uuid) => `/photobook/edit/${company_uuid}/${photobook_uuid}`,
    // get list photobook by bukken
    getPhotoBookListByBukken: (company_uuid, bukken_uuid) => `/photobook/list/${company_uuid}/${bukken_uuid}`,
    // delete logic photobook
    deletePhotobook: (company_uuid, photobook_uuid) => `/photobook/delete/${company_uuid}/${photobook_uuid}`,
    // export photobook
    exportPhotobook: (company_uuid, photobook_uuid) => `/photobook/export/${company_uuid}/${photobook_uuid}`,
    // get photobook by bukken
    getPhotosInMonth: (company_uuid, folder_uuid) => `/photobook/photos/${company_uuid}/${folder_uuid}`,
    // photo check
    photoCheck: (company_uuid, folder_uuid) => `/photobook/photo-check/${company_uuid}/${folder_uuid}`,
    // get Photo Note
    getPhotoNote: (company_uuid, file_uuid) => `/photobook/note/${company_uuid}/${file_uuid}`,
    // get template photobook
    getTemplate: (company_uuid, photobook_template_uuid) =>`/photobook/template/${company_uuid}/${photobook_template_uuid}`,
    //get list template
    getListTemplate: (company_uuid) => `/photobook/template/list/${company_uuid}`,
  }
};

export default API_CONFIG;
