/* eslint-disable no-debugger */
import { createSlice } from "@reduxjs/toolkit";

export const statePatternSlice = createSlice({
  name: "statePatternSlice",
  initialState: {
    searchCoditions: [],
    patternItem: {
      patternName: null,
      patternUuid: null
    }
  },
  reducers: {
    setSearchCoditions: (state, action) => {
      state.searchCoditions = action.payload;
    },
    setPatternItem: (state, action) => {
      state.patternItem.patternName = action.payload.patternName;
      state.patternItem.patternUuid = action.payload.patternUuid;
    },
    clearPatteritem: (state) => {
      state.patternItem = {
        patternName: null,
        patternUuid: null
      };
    }
  }
});

export const {
  setSearchCoditions,
  setPatternItem,
  clearPatteritem
} = statePatternSlice.actions;

export default statePatternSlice.reducer;
