import axios from "axios";
import API_CONFIG from "../config/apiConfig";
import TokenService from "../services/tokenService";
import { TIME_OUT_REQUEST } from "../utils/globalConstantUtil";

class InstanceAxios {
  constructor() {
    const accessToken = TokenService.getLocalAccessToken();
    this.instance = axios.create({
      baseURL: API_CONFIG.apiUrl,
      timeout: TIME_OUT_REQUEST,
      headers: {
        "Authorization":`Bearer ${accessToken}`
    },
    });
  }
  static getInstance() {
    if (!InstanceAxios.instance) {
      InstanceAxios.instance = new InstanceAxios();
    }
    return InstanceAxios.instance.instance;
  }
}
export const instanceAxios = InstanceAxios.getInstance();
